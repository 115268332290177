import { CameraOutlined, ClockCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import CircleIcon from '@mui/icons-material/Circle';
import { Card, Checkbox, Col, Row, Image } from 'antd';
import { ImageType } from 'helpers/types';
import moment from 'moment';
import React, { useState } from 'react';
import * as Res from 'res';
import { Fab, IconButton, ThemeProvider, Chip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { StarBorderOutlined } from '@mui/icons-material';
import outerTheme from 'theme/mui';
import HDVideoComponent from '../simbols/HDVideoComponent';
import classes from './style.module.scss';

interface props {
  index: number
  detail: ImageType
  onClick: (arg) => void;
  onSelect: (arg) => void;
  onFavorite: (index, isFavorite) => void;
}

const categoryColors = {
  Deer: Res.colorDeer_alpha2,
  Hog: Res.colorHog_alpha2,
  Turkey: Res.colorTurkey_alpha2,
  People: Res.colorPeople_alpha2,
  Vehicle: Res.colorVehicle_alpha2,
  Predator: Res.colorPredator_alpha2,
  Other: Res.colorOther_alpha2,
};

const ImageCard: React.FC<props> = ({ index, detail, onClick, onSelect, onFavorite }) => {
  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  detail.category = capitalizeFirstLetter(detail.category);

  const onClickImage = () => {
    onClick(index);
  };

  const onChangeCheckbox = (e) => {
    onSelect(e.target.checked);
  };

  const onChangeFavorite = (e) => {
    onFavorite(index, detail.favorite == 1 ? 0 : 1);
  };

  return (
    <div style={{ padding: 8 }}>
      <Card
        hoverable
        bodyStyle={{ padding: 0 }}
      >
        <div className={classes.cardCover}>
          {detail.category == 'Deer' && detail.category_sub == 'Antlered' && <img className={classes.img_antler} src="/icons/antler_icon.png" alt="" />}
          {detail.is_new == '1' && <CircleIcon className={classes.new_image} />}
          <Image
            className={classes.image}
            preview={false}
            src={`https://d7s85wyrr26qk.cloudfront.net/${detail.thumbnail == '' ? detail.image_name : detail.thumbnail}`}
            placeholder={(
              <Image
                preview={false}
                src="/icons/image_placeholder.png"
                className={classes.image}
              />
            )}
            onClick={() => onClickImage()}
          />
          <HDVideoComponent detail={detail} />
          <Checkbox className={classes.checkBox} onChange={onChangeCheckbox} checked={detail.selected} />
          {/* <img alt="" /> */}
          {/* {detail.thumbnail != '' && <img className={classes.img_video} src="/icons/play_button.png" alt="" />} */}
          <div className={classes.imageCategory} style={{ borderColor: categoryColors[detail.category], color: categoryColors[detail.category] }}>
            <div style={{ backgroundColor: categoryColors[detail.category] }}>&nbsp;</div>
            {detail.category}
          </div>
        </div>
        <div className={classes.divDetail}>
          <div>
            <div className={classes.labelCamera}><CameraOutlined />&nbsp;{detail.camera_name}</div>
            <div className={classes.labelTime}><ClockCircleOutlined />&nbsp;{moment(detail.time).format('hh:mm A - MMM D, Y')}</div>
          </div>
          <div className={classes.divStar}>
            <ThemeProvider theme={outerTheme}>
              <IconButton color={detail.favorite == 1 ? 'primary' : 'warning'} aria-label="like" onClick={onChangeFavorite}>
                {detail.favorite == 1 ? <StarIcon /> : <StarBorderOutlined />}
              </IconButton>
            </ThemeProvider>
          </div>
        </div>
      </Card>
      <Row style={{ marginTop: '3px' }}>
        <Col flex="auto">
          <ThemeProvider theme={outerTheme}>
            {detail.tag && <Chip variant="outlined" size="small" color="default" label={detail.tag.split('$', 2).length > 1 ? detail.tag.split('$', 2)[1] : detail.tag} />}
          </ThemeProvider>
        </Col>
      </Row>
    </div>
  );
};

export default ImageCard;
