import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import outerTheme from 'theme/mui';

const ProfileNotificationChecklist = (props) => {
  const [deerAntlered, setDeerAntlered] = useState(false);
  const [deerNoAntlered, setDeerNoAntlered] = useState(false);
  const [hog, setHog] = useState(false);
  const [turkey, setTurkey] = useState(false);
  const [people, setPeople] = useState(false);
  const [vehicle, setVehicle] = useState(false);
  const [predator, setPredator] = useState(false);
  const [other, setOther] = useState(false);

  useEffect(() => {
    if (props) {
      setDeerAntlered(props.values[0]);
      setDeerNoAntlered(props.values[1]);
      setHog(props.values[2]);
      setTurkey(props.values[3]);
      setPeople(props.values[4]);
      setVehicle(props.values[5]);
      setPredator(props.values[6]);
      setOther(props.values[7]);
    }
  }, [props]);

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeerAntlered(event.target.checked);
    setDeerNoAntlered(event.target.checked);

    const params = {
      deer: event.target.checked ? 1 : 0,
      hog: hog ? 1 : 0,
      turkey: turkey ? 1 : 0,
      people: people ? 1 : 0,
      vehicle: vehicle ? 1 : 0,
      predator: predator ? 1 : 0,
      other: other ? 1 : 0,
    };

    props.onChange(params);
  };

  const handleChange = (event, index) => {
    console.log('click', index);
    let deer = 0;
    if (deerAntlered && deerNoAntlered) {
      deer = 1;
    } else if (deerAntlered && !deerNoAntlered) {
      deer = 2;
    } else if (!deerAntlered && deerNoAntlered) {
      deer = 3;
    }
    let params = {
      deer,
      hog: hog ? 1 : 0,
      turkey: turkey ? 1 : 0,
      people: people ? 1 : 0,
      vehicle: vehicle ? 1 : 0,
      predator: predator ? 1 : 0,
      other: other ? 1 : 0,
    };

    if (index == 0) {
      setDeerAntlered(!deerAntlered);
      deer = 0;
      if (event.target.checked && deerNoAntlered) {
        deer = 1;
      } else if (event.target.checked && !deerNoAntlered) {
        deer = 2;
      } else if (!event.target.checked && deerNoAntlered) {
        deer = 3;
      }
      params = { ...params, ...{ deer } };
    } else if (index == 1) {
      setDeerNoAntlered(!deerNoAntlered);
      deer = 0;
      if (deerAntlered && event.target.checked) {
        deer = 1;
      } else if (deerAntlered && !event.target.checked) {
        deer = 2;
      } else if (!deerAntlered && event.target.checked) {
        deer = 3;
      }
      params = { ...params, ...{ deer } };
    } else if (index == 2) {
      setHog(!hog);
      params = { ...params, ...{ hog: event.target.checked ? 1 : 0 } };
    } else if (index == 3) {
      setTurkey(!turkey);
      params = { ...params, ...{ turkey: event.target.checked ? 1 : 0 } };
    } else if (index == 4) {
      setPeople(!people);
      params = { ...params, ...{ people: event.target.checked ? 1 : 0 } };
    } else if (index == 5) {
      setVehicle(!vehicle);
      params = { ...params, ...{ vehicle: event.target.checked ? 1 : 0 } };
    } else if (index == 6) {
      setPredator(!predator);
      params = { ...params, ...{ predator: event.target.checked ? 1 : 0 } };
    } else if (index == 7) {
      setOther(!other);
      params = { ...params, ...{ other: event.target.checked ? 1 : 0 } };
    }
    props.onChange(params);
  };

  return (
    <ThemeProvider theme={outerTheme}>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">{props.title}</FormLabel>
        <FormGroup>
          <div>
            <FormControlLabel
              label="Deer"
              control={(
                <Checkbox
                  checked={deerAntlered && deerNoAntlered}
                  indeterminate={deerAntlered !== deerNoAntlered}
                  onChange={handleChange1}
                />
              )}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
              <FormControlLabel
                label="Antlered"
                control={<Checkbox checked={deerAntlered} onChange={(e) => handleChange(e, 0)} />}
              />
              <FormControlLabel
                label="Non-Antlered"
                control={<Checkbox checked={deerNoAntlered} onChange={(e) => handleChange(e, 1)} />}
              />
            </Box>
          </div>
          <FormControlLabel
            control={
              <Checkbox checked={hog} onChange={(e) => handleChange(e, 2)} />
            }
            label="Hog"
          />
          <FormControlLabel
            control={
              <Checkbox checked={turkey} onChange={(e) => handleChange(e, 3)} />
            }
            label="Turkey"
          />
          <FormControlLabel
            control={
              <Checkbox checked={people} onChange={(e) => handleChange(e, 4)} />
            }
            label="People"
          />
          <FormControlLabel
            control={
              <Checkbox checked={vehicle} onChange={(e) => handleChange(e, 5)} />
            }
            label="Vehicle"
          />
          <FormControlLabel
            control={
              <Checkbox checked={predator} onChange={(e) => handleChange(e, 6)} />
            }
            label="Predator"
          />
          <FormControlLabel
            control={
              <Checkbox checked={other} onChange={(e) => handleChange(e, 7)} />
            }
            label="Other"
          />
        </FormGroup>
      </FormControl>
    </ThemeProvider>
  );
};

export default ProfileNotificationChecklist;
