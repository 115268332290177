import { CameraType, FilterType, ImageType } from 'helpers/types';
import { createReducer } from 'redux-act';
import * as actions from './api.action';

const initialState = {
  cameras: [],
  images: [],
  filterOptions: {
    length: 36,
    order_by: 'id',
    antlered: '0',
  },
  showLeftFilter: false,
  cameraMapBound: {
    center: {
      lat: 30.684755,
      lng: -90.969077,
    },
    zoom: 14,
  },
  tags: [],
  tag_assigns: [],
};

const reducer:any = {
  [actions.setShowLeftFilter]: (state: any, data: { showLeftFilter: boolean }) => {
    const { showLeftFilter } = data;
    return { ...state, showLeftFilter };
  },
  [actions.setFilterOption]: (state: any, data: { filterOptions: FilterType }) => {
    const { filterOptions } = data;
    return { ...state, filterOptions };
  },
  [actions.setCameras]: (state: any, data: { cameras:CameraType[] }) => {
    const { cameras } = data;
    return { ...state, cameras };
  },
  [actions.setImages]: (state: any, data: { images:ImageType[] }) => {
    const { images } = data;
    return { ...state, images };
  },
  [actions.setCameraMapBound]: (state: any, data: { cameraMapBound: any }) => {
    const { cameraMapBound } = data;
    return { ...state, cameraMapBound };
  },
  [actions.setTags]: (state: any, data: { tags: any }) => {
    const { tags } = data;
    return { ...state, tags };
  },
  [actions.setTagAssigns]: (state: any, data: { tag_assigns: any }) => {
    const { tag_assigns } = data;
    return { ...state, tag_assigns };
  },
};

export default createReducer(reducer, initialState);
