import React, { useEffect, useState } from 'react';
import HdIcon from '@mui/icons-material/Hd';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import FourKIcon from '@mui/icons-material/FourK';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import classes from './style.module.scss';

function HDVideoComponent({ detail }) {
  if (detail.thumbnail != '') {
    return <PlayCircleIcon className={classes.img_video} />;
  }
  if (detail.is_hd == 1) {
    return <img src="/icons/size-xl.png" className={classes.hd_image} alt="" />;
  }
  if (detail.is_thumbnail == 1) {
    return <PhotoSizeSelectLargeIcon className={classes.img_video} />;
  }
  if (detail.is_thumbnail == 3) {
    return <img src="/icons/img_icon_as.png" className={classes.hd_image} alt="" />;
  }
  return null;
}

export default HDVideoComponent;
