import { Button, Card, Col, Collapse, Divider, Dropdown, Form, Input, Menu, Modal, Popconfirm, Popover, Radio, Row, Select, Spin, Switch, TimePicker, Tooltip, notification } from 'antd';
import * as Api from 'api';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { optionsDC2 } from '../options';
import classes from './style.module.scss';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const format = 'HH:mm';

const CameraRemoteDC2Advanced = ({ IMEI, setting, onChange, onSend }) => {
  const [form] = Form.useForm();
  const [password, setPassword] = useState('');
  const [videoLength, setVideoLength] = useState('3');
  const [loading, setLoading] = useState(false);
  const [switchTimeLapse, setSwitchTimeLapse] = useState(true);
  const [workTimer1, setWorkTimer1] = useState(true);
  const [workTimer2, setWorkTimer2] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPasswordOpen, setIsModalPasswordOpen] = useState(false);
  const [isModalPlayVideo, setIsModalPlayVideo] = useState(false);
  const [isModalFormat, setIsModalFormat] = useState(false);
  const [isModalReset, setIsModalReset] = useState(false);
  const [formatType, setFormatType] = useState(1);
  const [switchPassword, setSwitchPassword] = useState(false);

  useEffect(() => {
    if (setting) {
      console.log('setting', setting);

      let lapse = setting.time_lapse;
      if (lapse == 'OFF') {
        setSwitchTimeLapse(false);
        lapse = '00:00:00';
      } else {
        setSwitchTimeLapse(true);
      }

      let work_timer1 = [moment('00:00', format), moment('00:00', format)];
      if (setting.work_timer1 == 'OFF') {
        setWorkTimer1(false);
      } else {
        setWorkTimer1(true);
        const work_time = setting.work_timer1.split('-');
        work_timer1 = [moment(work_time[0], format), moment(work_time[1], format)];
      }

      let work_timer2 = [moment('00:00', format), moment('00:00', format)];
      if (setting.work_timer2 == 'OFF') {
        setWorkTimer2(false);
      } else {
        setWorkTimer2(true);
        const work_time = setting.work_timer2.split('-');
        work_timer2 = [moment(work_time[0], format), moment(work_time[1], format)];
      }
      form.setFieldsValue({ ...setting, timeLapseMoment: moment(lapse, 'HH:mm:ss'), workTimerMoment1: work_timer1, workTimerMoment2: work_timer2 });
    }
  }, [setting]);

  const onReset = () => {
    const params = {
      IMEI,
    };
    Api.DC2_RESET(params).then((res: any) => {
      notification.open({
        message: res.text,
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onFirmwareUpdate = () => {
    setIsModalOpen(true);
  };

  const onGetGPS = () => {
    const params = {
      IMEI,
    };
    Api.DC2_GET_GPS(params).then((res: any) => {
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        notification.open({
          message: data.message,
        });
      } else {
        notification.open({
          message: data.message,
          duration: 10,
        });
      }
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onFinish = () => {
    onSend();
  };

  const onChangeForm = () => {
    const values = form.getFieldsValue();
    if (Object.keys(values).length > 0) {
      const updatedSetting = {};
      for (let [key, value] of Object.entries(values)) {
        if (key == 'timeLapseMoment') {
          key = 'time_lapse';
          if (switchTimeLapse) {
            value = values.timeLapseMoment.format('HH:mm:ss');
          } else {
            value = 'OFF';
          }
        } else if (key == 'workTimerMoment1') {
          key = 'work_timer1';
          if (workTimer1) {
            const time1 = values.workTimerMoment1[0].format(format);
            const time2 = values.workTimerMoment1[1].format(format);
            value = `${time1}-${time2}`;
          } else {
            value = 'OFF';
          }
        } else if (key == 'workTimerMoment2') {
          key = 'work_timer2';
          if (workTimer2) {
            const time1 = values.workTimerMoment2[0].format(format);
            const time2 = values.workTimerMoment2[1].format(format);
            value = `${time1}-${time2}`;
          } else {
            value = 'OFF';
          }
        }
        updatedSetting[key] = value;
        key = '';
      }
      onChange(updatedSetting);
    }
  };

  const http_formware_update = () => {
    setIsModalOpen(false);
    const params = {
      IMEI,
    };
    Api.DC2_FIRMWARE_UPDATE(params).then((res: any) => {
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        const date = new Date(data.estimated_time * 1000).toLocaleString();
        const confirm_message = `Your update request has been sent to the camera and should be completed by ${date}. Please check back at that time.`;
        notification.open({
          message: confirm_message,
        });
      } else {
        notification.open({
          message: data.message,
          duration: 10,
        });
      }
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const http_play_video = () => {
    setIsModalPlayVideo(false);
    const params = {
      IMEI,
      length: videoLength,
    };
    Api.DC2_PLAY_VIDEO(params).then((res: any) => {
      notification.open({
        message: res.text,
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const http_format = (e) => {
    setIsModalFormat(false);
    const params = {
      IMEI,
      option: formatType,
    };
    Api.DC2_FORMAT(params).then((res: any) => {
      notification.open({
        message: res.text,
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const http_password = () => {
    let value = password;
    if (!switchPassword) {
      value = '';
    } else if (value.length < 6 && value.length > 0) {
      return;
    }
    setIsModalPasswordOpen(false);
    const params = {
      IMEI,
      password: value,
    };
    Api.DC2_PASSWORD(params).then((res: any) => {
      notification.open({
        message: res.text,
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <Spin spinning={loading} size="large">
      <Collapse defaultActiveKey={[]}>
        <Collapse.Panel header="Advanced Settings" key="1">
          <Form
            {...layout}
            form={form}
            name="basic"
            onValuesChange={onChangeForm}
            onFinish={onFinish}
          >
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Burst Interval"
                  name="burst_interval"
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsDC2.BurstInterval}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Sending Mode"
                  name="sending_mode"
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsDC2.SendingMode}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="IR Flash"
                  name="ir_flash"
                  tooltip={{ title: 'Choose how much flash you wish to use for night time pictures. Low power is better for close subjects. High power tends to wash out close subjects. Low power will reduce visible range in the pictures.', placement: 'bottom' }}
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsDC2.IRFlash}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Time Lapse:"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'Allows you to take pictures at an interval, regardless of the motion sensor. You can set the interval as low as 5 seconds or as high as 23 hrs and 59 minutes. Turning on Time Lapse will disable your motion sensor. If you want the camera to be triggered by motion, make sure the Time Lapse is not enabled.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="timeLapseMoment"
                    >
                      <TimePicker
                        className={classes.control}
                        size="large"
                        disabled={!switchTimeLapse}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={switchTimeLapse}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setSwitchTimeLapse(checked);
                          if (checked) {
                            form.setFieldsValue({ timeLapseMoment: moment('00:00:03', 'HH:mm:ss') });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Work Timer1"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="workTimerMoment1"
                    >
                      <TimePicker.RangePicker
                        className={classes.control}
                        size="large"
                        disabled={!workTimer1}
                        format={format}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={workTimer1}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setWorkTimer1(checked);
                          if (checked) {
                            form.setFieldsValue({ workTimerMoment1: moment('00:00:03', 'HH:mm:ss') });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Work Timer2"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="workTimerMoment2"
                    >
                      <TimePicker.RangePicker
                        className={classes.control}
                        size="large"
                        disabled={!workTimer2}
                        format={format}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={workTimer2}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setWorkTimer2(checked);
                          if (checked) {
                            form.setFieldsValue({ workTimer2Moment: moment('00:00:03', 'HH:mm:ss') });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
            <>
              <div className={classes.divFooter}>
                <Button type="primary" htmlType="submit">
                  Send
                </Button>
              </div>
              <Divider />
              <div className={classes.divBottom}>
                <div className={classes.divLine}>
                  <Button type="primary" onClick={onFirmwareUpdate}>
                    Camera Firmware Update
                  </Button>
                </div>
                <div className={classes.divLine}>
                  <Button type="primary" onClick={() => setIsModalReset(true)}>
                    Reset
                  </Button>
                </div>
                <div className={classes.divLine}>
                  <Button type="primary" onClick={onGetGPS}>
                    Request GPS Position
                  </Button>
                </div>
                <div className={classes.divLine}>
                  <Button type="primary" onClick={() => setIsModalPlayVideo(true)}>
                    ActionShot
                  </Button>
                </div>
                <div className={classes.divLine}>
                  <Button type="primary" onClick={() => setIsModalFormat(true)}>
                    Format
                  </Button>
                </div>
                <div className={classes.divLine}>
                  <Button type="primary" onClick={() => setIsModalPasswordOpen(true)}>
                    Password
                  </Button>
                </div>
              </div>
            </>
          </Form>
          <Modal title="" visible={isModalReset} onCancel={() => { setIsModalReset(false); }} onOk={() => { setIsModalReset(false); onReset(); }} okText="Reset">
            <p>The reset command can resolve many common issues with the camera. Only use if you are having trouble with your camera. This will remove all of your settings from the camera. Do you wish to proceed?</p>
          </Modal>
          <Modal title="ActionShot" visible={isModalPlayVideo} onCancel={() => { setIsModalPlayVideo(false); }} onOk={http_play_video}>
            <p>ActionShot videos are too large to be sent over cellular, please retrieve your video from the SD card or internal memory of the camera. If you have an SD card in the camera, the video should be on the SD card.</p>
            <Radio.Group value={videoLength} onChange={(arg) => setVideoLength(arg.target.value)}>
              <Radio.Button value="3">3 minutes</Radio.Button>
              <Radio.Button value="4">4 minutes</Radio.Button>
              <Radio.Button value="5">5 minutes</Radio.Button>
            </Radio.Group>
          </Modal>
          <Modal title="" visible={isModalFormat} onCancel={() => { setIsModalFormat(false); }} okText="Format" onOk={http_format}>
            <p>{`This will remove all files from the ${formatType == 1 ? 'internal memory' : 'SD Card'} inside the camera. You will not be able to retrieve the files from the camera. This will not affect images that are already in HuntControl. This will not affect any settings on the camera.`}</p>
            <Radio.Group defaultValue="1" onChange={(arg) => setFormatType(arg.target.value)}>
              <Radio.Button value="1">Internal Memory</Radio.Button>
              <Radio.Button value="2">SD Card</Radio.Button>
            </Radio.Group>
          </Modal>
          <Modal title="" visible={isModalOpen} onOk={http_formware_update} onCancel={() => { setIsModalOpen(false); }}>
            <p>The update may take up to 48hrs to complete. During this time you will be unable to send additional remote control commands. The camera will have to have sufficient battery power and signal for the update to complete properly. After the update, your camera will revert to the default values for all settings. Your camera will continue to send images during the update process. None of your images or data will be affected during the update. Do you wish to proceed?</p>
          </Modal>
          <Modal title="" visible={isModalPasswordOpen} onOk={http_password} onCancel={() => { setIsModalPasswordOpen(false); }}>
            <Form
              {...layout}
              name="basic"
            >
              <Form.Item
                label="Password"
                style={{ marginBottom: 0 }}
              >
                <Input.Group compact>
                  <Form.Item
                    label=""
                    name="password"
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        pattern: new RegExp('^[0-9]{6,6}$'),
                        message: 'It must be a 6-digit.',
                      },
                    ]}
                  >
                    <Input
                      maxLength={6}
                      placeholder="OFF"
                      type="password"
                      disabled={!switchPassword}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    label=""
                    style={{ marginBottom: 0 }}
                  >
                    <Switch
                      checked={switchPassword}
                      onChange={(checked) => {
                        console.log(`switch to ${checked}`);
                        setSwitchPassword(checked);
                        if (!checked) {
                          setPassword('');
                        }
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Form>
          </Modal>
        </Collapse.Panel>
      </Collapse>
    </Spin>
  );
};

export default CameraRemoteDC2Advanced;
