import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Col, Divider, Drawer, Grid, Radio, Row, Spin } from 'antd';
import * as Api from 'api';
import SubImageCard from 'components/sub/SubImageCard';
import SubImageFilter from 'components/sub/SubImageFilter';
import { SubImageType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useParams } from 'react-router-dom';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

interface ParamTypes {
  image_filter: string
}

const SubImagePage = () => {
  const { auth: { profile } } = useAuth();
  const { image_filter } = useParams<ParamTypes>();
  const { md } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const { filterOptions, setFilterOption, showLeftFilter, setShowLeftFilter } = useApi();
  const [cameraArr, setCameraArr] = useState<any[]>([]);
  const [cameraGroups, setCameraGroups] = useState<any[]>([]);
  const [images, setImages] = useState<SubImageType[]>([]);
  const history = useHistory();
  let groupImageDate = '';

  const getImages = (start, more) => {
    setLoading(true);
    Api.SUB_IMAGE_ALL({ ...filterOptions, category: image_filter == 'buck' ? 'deer' : image_filter, start, length: 36 }).then(((res: any) => {
      if (more) {
        setImages(images.concat(JSON.parse(res.text).data));
      } else {
        setImages(JSON.parse(res.text).data);
      }
      setLoading(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
    setStartIndex(start);
  };

  useEffect(() => {
    if (filterOptions) {
      reloadPage();
    }
  }, [filterOptions]);
  const onScroll = () => {
    getImages(startIndex + 36, true);
  };

  const reloadPage = () => {
    setImages([]);
    getImages(0, false);
  };

  const handleRightFilter = (willShow) => {
    setShowLeftFilter(willShow);
  };

  const changecategory = (e) => {
    const val = e.target.value;
    if (val == 'deer') {
      setFilterOption({ ...filterOptions, category: val, antlered: '0' });
    } else {
      setFilterOption({ ...filterOptions, category: val });
    }
    history.push(`/sub_image/${val}`);
  };

  useEffect(() => {
    if (profile) {
      Api.CAMERAS_GROUPS().then(((res: any) => {
        const result = JSON.parse(res.text);
        setCameraArr(result.cameras);
        setCameraGroups(result.cameraGroups);
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
      setFilterOption({ ...filterOptions, user_id: profile.user_id });
    }
  }, [profile]);

  return (
    <>
      <Row>
        {md && (
          <Col md={6} xxl={4}>
            <div className={classes.imageFilter}>
              <SubImageFilter
                cameraGroups={cameraGroups}
                cameraArr={cameraArr}
                imageFilter={image_filter}
                reloadPage={() => reloadPage()}
              />
            </div>
          </Col>
        )}
        {md == false && (
          <Drawer
            title="Filter & Edit"
            width={300}
            onClose={() => handleRightFilter(false)}
            visible={showLeftFilter}
            zIndex={900}
            handler={
              (
                <div className={classes.drawerHandle}>
                  {
                    showLeftFilter ? <CloseOutlined onClick={() => handleRightFilter(false)} /> : <FilterOutlined onClick={() => handleRightFilter(true)} />
                  }
                </div>
              )
            }
          >
            <SubImageFilter
              cameraGroups={cameraGroups}
              cameraArr={cameraArr}
              imageFilter={image_filter}
              reloadPage={() => reloadPage()}
            />
          </Drawer>
        )}
        <Col md={18} xs={24} xxl={20}>
          <Radio.Group defaultValue={image_filter} buttonStyle="solid" className={classes.categoryGroup} onChange={changecategory}>
            <Row>
              <Col span={md ? 3 : 6} key={1}><Radio.Button className={classes.categoryBtn} value="all">All</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={2}><Radio.Button className={classes.categoryBtn} value="deer">Deer</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={3}><Radio.Button className={classes.categoryBtn} value="hog">Hog</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={4}><Radio.Button className={classes.categoryBtn} value="turkey">Turkey</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={5}><Radio.Button className={classes.categoryBtn} value="people">People</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={6}><Radio.Button className={classes.categoryBtn} value="vehicle">Vehicle</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={7}><Radio.Button className={classes.categoryBtn} value="predator">Predator</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={8}><Radio.Button className={classes.categoryBtn} value="other">Other</Radio.Button></Col>
            </Row>
          </Radio.Group>
          <Spin spinning={loading} size="large" wrapperClassName={classes.content}>
            <InfiniteScroll
              dataLength={images.length}
              next={onScroll}
              hasMore={!loading}
              loader=""
              endMessage={
                (
                  <p style={{ textAlign: 'center' }}>
                    <b>No more images</b>
                  </p>
                )
              }
            >
              <Row className={classes.content}>
                {
                  images.map((e, i) => {
                    const date = e.time.split(' ')[0];
                    if (date == groupImageDate) {
                      return (
                        <Col xs={12} md={6} xxl={4} key={i}>
                          <SubImageCard key={i} detail={e} />
                        </Col>
                      );
                    }
                    groupImageDate = date;
                    return (
                      <React.Fragment key={i}>
                        <Divider key={`Divider${i}`} orientation="left" className={classes.groupDivider}>{groupImageDate}</Divider>
                        <Col xs={12} md={6} xxl={4} key={i}>
                          <SubImageCard key={i} detail={e} />
                        </Col>
                      </React.Fragment>
                    );
                  })
                }
              </Row>
            </InfiniteScroll>
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default SubImagePage;
