import { Button, Card, Col, Divider, Form, Input, notification, Row, Select, Spin, Switch, TimePicker, Tooltip } from 'antd';
import * as Api from 'api';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { optionsDC2B } from '../options';
import classes from './style.module.scss';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const format = 'HH:mm';

const CameraRemoteBasic = ({ IMEI, setting, onChange, onSend }) => {
  const [form] = Form.useForm();
  const [switchName, setSwitchName] = useState(true);
  const [switchDelay, setSwitchDelay] = useState(true);
  const [burstModeEnabled, setBurstModeEnabled] = useState(true);
  const [cameraCheckin, setCameraCheckin] = useState<any>(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (setting) {
      if (setting.name == '' || setting.name == 'OFF') {
        setSwitchName(false);
      } else {
        setSwitchName(true);
      }

      if (setting.mode == 2) {
        setBurstModeEnabled(false);
        form.setFieldsValue({ multi_shot: 1 });
      } else {
        setBurstModeEnabled(true);
      }

      let delay_time_value = setting.delay_time;
      if (delay_time_value == 'OFF') {
        setSwitchDelay(false);
        delay_time_value = moment('00:00:00', 'HH:mm:ss');
      } else {
        setSwitchDelay(true);
        delay_time_value = moment(delay_time_value, 'HH:mm:ss');
      }
      form.setFieldsValue({ ...setting, delay_time_value });
      setCameraCheckin(setting.remote_ctrl);
    }
  }, [setting]);

  useEffect(() => {
    if (cameraCheckin == 1) {
      form.setFieldsValue({ frequency: 0 });
      onChangeForm();
    }
  }, [cameraCheckin]);

  const onChangeCameraMode = (value) => {
    console.log(value);
    if (value == 2) {
      setBurstModeEnabled(false);
      form.setFieldsValue({ multi_shot: 1 });
    } else {
      setBurstModeEnabled(true);
    }
  };

  const onChangeCameraCheckIn = (value) => {
    setCameraCheckin(value);
  };

  useEffect(() => {
    onChangeForm();
  }, [switchName, switchDelay]);

  const onChangeForm = () => {
    const values = form.getFieldsValue();
    const updatedSetting = {};
    for (let [key, value] of Object.entries(values)) {
      if (key == 'delay_time_value') {
        key = 'delay_time';
        if (value && switchDelay) {
          value = values.delay_time_value.format('HH:mm:ss');
        } else {
          value = 'OFF';
        }
      } else if (key == 'name') {
        // if (value == '') {
        //   value = 'OFF';
        // }
      }
      updatedSetting[key] = value;
      key = '';
    }

    onChange(updatedSetting);
  };

  const onFinish = () => {
    onSend();
  };

  const disabledSeconds = (hour: number, minute: number) => {
    if (hour == 0 && minute == 0) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    }
    return [];
  };

  const http_sendcommand = (arg) => {
    const params = {
      IMEI,
      command: arg,
      param: '',
    };
    setLoading(true);
    Api.DC2B_SEND_COMMAND(params).then((res: any) => {
      setLoading(false);
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        notification.open({
          message: data.message,
        });
      } else {
        notification.open({
          message: res.text,
          duration: 10,
        });
      }
    }).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        onValuesChange={onChangeForm}
        onFinish={onFinish}
      >
        <Spin spinning={loading} size="large">
          <Card className={classes.card}>
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Camera Mode"
                  name="mode"
                  tooltip={{ title: 'Choose whether you wish to receive videos, pictures or both videos and pictures. Please be aware that receiving videos will use significantly more data than just pictures.', placement: 'bottom' }}
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsDC2B.CameraMode}
                    onChange={onChangeCameraMode}
                  />
                </Form.Item>
                <Form.Item
                  label="Burst Mode"
                  name="multi_shot"
                  tooltip={{ title: 'Choose how many pictures you would like the camera to take with each motion trigger. This only works in Photo mode.', placement: 'bottom' }}
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsDC2B.MultiShot}
                    disabled={!burstModeEnabled}
                  />
                </Form.Item>
                <Form.Item
                  label="Picture Size"
                  name="pic_size"
                  tooltip={{ title: 'This is the size of the picture that is saved on the SD Card. This will not affect the size of the images transmitted to HuntControl.', placement: 'bottom' }}
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsDC2B.PictureSize}
                  />
                </Form.Item>
                <Form.Item
                  label="Video Quality"
                  name="video_size"
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsDC2B.VideoSize}
                  />
                </Form.Item>
                <Form.Item
                  label="Video Length"
                  name="video_length"
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsDC2B.VideoLength}
                  />
                </Form.Item>
                <Form.Item
                  label="Video Upload"
                  name="transvideo"
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsDC2B.VideoSending}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Motion Sensitivity"
                  name="motion_sensitivity"
                  tooltip={{ title: '9 - more sensitive to motion. 1 - least sensitive to motion.', placement: 'bottom' }}
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsDC2B.PirSensitivity}
                  />
                </Form.Item>
                <Form.Item
                  label="Camera Check In"
                  name="remote_ctrl"
                  tooltip={{ title: 'Once a Day - Best Battery Life, Up to 24hrs to execute remote control commands. Always Available - Instantly Execute commands, significant battery drain. Recommended only for those with solar panels or larger battery adapters.', placement: 'bottom' }}
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsDC2B.Cellular}
                    onChange={onChangeCameraCheckIn}
                  />
                </Form.Item>
                <Form.Item
                  label="Sending Mode"
                  name="frequency"
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={cameraCheckin == 0 ? optionsDC2B.SendingMode0 : optionsDC2B.SendingMode1}
                  />
                </Form.Item>
                <Form.Item
                  label="Delay"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'This is the amount of time that the camera will wait between taking pictures.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="delay_time_value"
                    >
                      <TimePicker
                        className={classes.control}
                        size="large"
                        disabledSeconds={disabledSeconds}
                        showNow={false}
                        allowClear={false}
                        disabled={!switchDelay}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={switchDelay}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setSwitchDelay(checked);
                          // if (checked) {
                          //   form.setFieldsValue({ delayMoment: moment('00:00:10', 'HH:mm:ss') });
                          // }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item label="Camera Name" className={classes.divTimeLapse}>
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="name"
                      rules={[
                        {
                          pattern: new RegExp('^[0-9A-Za-z ]+$'),
                          message: 'It must be only latters, numbers, spaces.',
                        },
                      ]}
                    >
                      <Input
                        disabled={!switchName}
                        maxLength={12}
                        placeholder="OFF"
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={switchName}
                        onChange={(value) => {
                          setSwitchName(value);
                          if (value) {
                            form.setFieldsValue({ name: '' });
                          } else {
                            form.setFieldsValue({ name: 'OFF' });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className={classes.divFooter}>
              <Button type="primary" htmlType="submit">
                Send
              </Button>
            </div>
            <Divider />
            <div className={classes.divBottom}>
              <div className={classes.divLine}>
                <Tooltip title="This will tell the camera to send you a picture the next time that the camera checks in. If your camera is set to Daily Cellular Mode then it may take up to 24hrs to get the image. If your camera is set to Instant Cellular Mode then it should send the picture in a few minutes at most. The picture will show in your image gallery.">
                  <Button type="primary" onClick={() => http_sendcommand('get_pic')}>
                    Get A Picture
                  </Button>
                </Tooltip>
              </div>
            </div>
          </Card>
        </Spin>
      </Form>
    </>
  );
};

export default CameraRemoteBasic;
