import { Spin, notification } from 'antd';
import * as Api from 'api';
import React, { useEffect, useState } from 'react';
import CameraRemoteBigAdvanced from './advanced';
import CameraRemoteBigBasic from './basic';
import { optionsBig } from './options';
import CameraRemoteMiniBigReport from './report';

const CameraRemoteBig = (props) => {
  const [loading, setLoading] = useState(false);
  const [settingBasic, setSettingBasic] = useState<any>(null);
  const [settingAdvanced, setSettingAdvanced] = useState<any>(null);
  const [settingBasicInit, setSettingBasicInit] = useState<any>(null);
  const [settingAdvancedInit, setSettingAdvancedInit] = useState<any>(null);
  const [deviceSetting, setDeviceSetting] = useState({
    camera_mode: '',
    burst_mode: '',
    motion_sensitivity: '',
    picture_size: '',
    camera_check_int: '',
    night_mode: '',
    delay: '',
    camera_name: '',
    time_lapse: '',
    work_timer1: '',
    work_timer2: '',
    sd_card: 0,
    sd_card_max: 0,
  });

  useEffect(() => {
    const { device } = props;
    // console.log('device', device);
    if (device) {
      const newSettingBasic = {
        camera_mode: device.camera_mode,
        multi_shot: device.multi_shot,
        pir_sensitivity: device.pir_sensitivity,
        picture_size: device.picture_size,
        sms_remote: device.sms_remote,
        delay: device.delay,
      };
      setSettingBasic(newSettingBasic);
      setSettingBasicInit(newSettingBasic);
      const newSettingAdvanced = {
        night_mode: device.night_mode,
        time_lapse: device.time_lapse,
        work_timer1: device.work_timer1,
        work_timer2: device.work_timer2,
      };
      setSettingAdvanced(newSettingAdvanced);
      setSettingAdvancedInit(newSettingAdvanced);
      http_load_device_setting(props.camera.IMEI);
    }
  }, [props.device]);

  useEffect(() => {
    console.log('camera', props.device);
    http_load_device_setting(props.camera.IMEI);
  }, [props.device]);

  const onChangeAdvanced = (values) => {
    setSettingAdvanced(values);
  };

  const onChangeBasic = (values) => {
    setSettingBasic(values);
  };

  const onSend = () => {
    http_update_device_setting(settingBasic, settingAdvanced);
  };

  const http_update_device_setting = (basic, advanced) => {
    const different_settings = { IMEI: props.camera.IMEI, ...basic, ...advanced };
    console.log('http_update_device_setting', different_settings);

    setLoading(true);
    Api.REMOTE_CONTROL_STANDARD(different_settings).then(((res: any) => {
      setLoading(false);
      notification.open({
        message: 'Your new settings have been sent to your camera and will take effect the next time your camera connects to the server.',
      });
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  const getLabel = (value, options) => {
    for (let i = 0; i < options.length; i++) {
      if (parseInt(value, 10) == options[i].value) {
        return options[i].label;
      }
    }
    return '';
  };

  const http_load_device_setting = (IMEI) => {
    Api.REMOTE_CONTROL_DEVICE_SETTING(IMEI).then(((data: any) => {
      const res = JSON.parse(data.text);
      console.log(res);
      if (res.result == 'OK') {
        const new_device = {
          camera_mode: getLabel(res.device.camera_mode, optionsBig.CameraMode),
          burst_mode: getLabel(res.device.multi_shot, optionsBig.MultiShot),
          motion_sensitivity: getLabel(res.device.pir_sensitivity, optionsBig.PirSensitivity),
          picture_size: getLabel(res.device.picture_size, optionsBig.PictureSize),
          camera_check_int: getLabel(res.device.sms_remote, optionsBig.Cellular),
          night_mode: getLabel(res.device.night_mode, optionsBig.NightMode),
          delay: res.device.delay,
          camera_name: res.device.camera_name,
          time_lapse: res.device.time_lapse,
          work_timer1: res.device.work_timer1,
          work_timer2: res.device.work_timer2,
          sd_card: res.device.sd_card,
          sd_card_max: res.device.sd_card_max,
        };
        setDeviceSetting(new_device);
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <>
      <Spin spinning={loading} size="large">
        <CameraRemoteBigBasic IMEI={props.camera.IMEI} setting={settingBasicInit} onChange={onChangeBasic} onSend={onSend} />
        <CameraRemoteBigAdvanced setting={settingAdvancedInit} onChange={onChangeAdvanced} onSend={onSend} />
        <CameraRemoteMiniBigReport camera={props.camera} device={props.device} deviceSetting={deviceSetting} />
      </Spin>
    </>
  );
};

export default CameraRemoteBig;
