import { Card, Tooltip } from 'antd';
import { CameraPinDetailType } from 'helpers/types';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AttComponent from 'components/camera/cameraSimbols/AttComponent';
import BatteryComponent from 'components/camera/cameraSimbols/BatteryComponent';
import BatteryComponentMC2 from 'components/camera/cameraSimbols/BatteryComponentMC2';
import SignalComponent from 'components/camera/cameraSimbols/SignalComponent';
import SignalComponentMC2 from 'components/camera/cameraSimbols/SignalComponentMC2';
import TypeComponent from 'components/camera/cameraSimbols/TypeComponent';
import classes from './style.module.scss';

interface props {
  detail: CameraPinDetailType,
}

// function TypeComponent(props) {
//   const { detail } = props;
//   if (detail.version == 'A') {
//     return <div className={classes.labelType}>Standard</div>;
//   }
//   return <div className={classes.labelType}>Mini</div>;
// }

// function AttComponent(props) {
//   const { detail } = props;
//   if (detail.att_verizon == 'ATT') {
//     return <img className={classes.image} alt="test" src="/icons_camera/carrier_logo_att.png" />;
//   }
//   return <img className={classes.image} alt="test" src="/icons_camera/carrier_logo_verizon.png" />;
// }

const CameraCard: React.FC<props> = ({ detail }) => (
  <div style={{ padding: 4 }}>
    <Card
      hoverable
      bodyStyle={{ padding: 8 }}
    >
      <Link to={detail.type == '' || detail.type == null ? `/cameraEdit/${detail.id}` : `/cameraEditPin/${detail.id}`}>
        <div className={classes.divBody}>
          <img className={classes.image} alt="test" src={`/map_pins/${detail.type ? detail.type : 'Camera'}.png`} />
          <div className={classes.divLeft}>
            <div className={classes.divBody}>
              <div className={classes.labelName}>{detail.name} </div>&nbsp;&nbsp;
              <div className={classes.labelIMEI}>{detail.IMEI}</div>
              {
                Math.abs(detail.latitude) < 2 && Math.abs(detail.longitude) < 2 && (
                  <Tooltip title="The location information for this camera seems to be incorrect. Update the location to get the correct weather information based on the camera's location.">
                    <ErrorOutlineIcon />
                  </Tooltip>
                )
              }
            </div>
            {(detail.type == '' || detail.type == null) && detail.IMEI != '' && <div className={classes.labelDate}>Last Activity: {detail.last_text_uploaded_at > 0 ? moment(detail.last_text_uploaded_at * 1000).format('hh:mm A - MMM D, Y') : ''}</div>}
          </div>
          {
            (detail.type == '' || detail.type == null) && detail.IMEI != '' && (
              <>
                {detail.is_active == 0 && (
                  <div className={classes.divActivate}>
                    Suspended
                  </div>
                )}
                <>
                  <div className={classes.divCarrier}>
                    <AttComponent detail={detail} />
                    <TypeComponent detail={detail} />
                  </div>
                  <div className={classes.divRight}>
                    {(detail.version == 'MC2' || detail.version == 'DC2B') && detail.battery1 != '' && <BatteryComponentMC2 battery1={detail.battery1} battery2={detail.battery2} />}
                    {!(detail.version == 'MC2' || detail.version == 'DC2B') && <BatteryComponent detail={detail} />}
                  </div>
                  <div className={classes.iconSignal}>
                    {(detail.version == 'MC2' || detail.version == 'DC2B') && <SignalComponentMC2 csq_percent={detail.csq_percent} fontSize="medium" />}
                    {!(detail.version == 'MC2' || detail.version == 'DC2B') && <SignalComponent detail={detail} />}
                  </div>
                </>
              </>
            )
          }
        </div>
      </Link>
    </Card>
  </div>
);

export default CameraCard;
