import { Col, Grid, Row } from 'antd';
import bgAuth from 'assets/image/bg-auth.jpg';
import logoPng from 'assets/image/hunt_control_logo.png';
import localStore from 'helpers/localstore';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from 'redux/auth/auth.hook';
import history from 'redux/history';
import { PATHS } from 'res/routes';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

interface ParamTypes {
  token: string,
}
const AuthSignInTokenPage = () => {
  const { md } = useBreakpoint();
  const { token } = useParams<ParamTypes>();
  const { auth } = useAuth();

  console.log('token ==>', token);
  useEffect(() => {
    if (token) {
      // const name = decodeURIComponent(username);
      // const pass = decodeURIComponent(password);
      // signRequestSaga({ username: name, password: pass });

      localStore.setToken(token);
      history.push(PATHS.HOME);
    }
  }, [token]);

  return (
    <Row className={classes.authWrapper}>
      {md && (
        <Col md={8}>
          <div className={classes.leftSide}>
            <img className={classes.authBg} src={bgAuth} alt="" />
            <div className={classes.logoWrapper}>
              <img src={logoPng} alt="" />
              <h1>HuntControl</h1>
            </div>

          </div>
        </Col>
      )}
      <Col md={16} xs={24}>
        <div className={classes.rightpanel}>
          <div className={classes.siginFormWrapper}>
            <h1> Wait for a while</h1>
          </div>
        </div>
        {md && (
          <div className={classes.bottomContact}>
            <div className={classes.divContact}>
              <p>Email is support@wiseeyetech.com</p>
              <p>Phone is 225-478-4026 </p>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default AuthSignInTokenPage;
