import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Col, Drawer, Grid, Modal, Radio, Row, Spin, notification } from 'antd';
import * as Api from 'api';
import MediaBox from 'components/image/MediaBox';
import FilterTrash from 'components/image/filterTrash';
import ImageCard from 'components/image/imageCard';
import { ImageType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import useApi from 'redux/api/api.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

interface ParamTypes {
  image_filter: string
}

const TrashPage = () => {
  const [loading, setLoading] = useState(false);
  const { image_filter } = useParams<ParamTypes>();
  const [category, setCategory] = useState('');
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const [images, setImages] = useState<ImageType[]>([]);
  const { showLeftFilter, setShowLeftFilter } = useApi();
  const { md } = useBreakpoint();

  const [filters, setFilters] = useState<any>({
    category: '',
    camera_id: '0',
    fromDate: '',
    toDate: '',
    tag: '',
    xl_only: false,
    video_only: false,
    favorite_only: false,
    antlered: 0,
    order_by: 'id',
    length: 36,
    sort: 'desc',
  });

  useEffect(() => {
    setCategory(image_filter);
  }, [image_filter]);

  useEffect(() => {
    setCategory(filters.category);
    httpLoadData(false);
  }, [filters]);

  const httpLoadData = (more) => {
    setLoading(true);
    let lastId = '-1';
    let lastTime = '';
    let category_api = filters.category;
    if (filters.antlered == '1') {
      category_api = 'Deer (Antlered)';
    }
    let api_sort = filters.sort;
    if (api_sort == null) {
      api_sort = 'desc';
    }
    if (more && images.length > 0) {
      lastId = images[images.length - 1].id;
      lastTime = images[images.length - 1].time;
    }

    const params = {
      cameras: (filters.camera_id == null || filters.camera_id == 0) ? [] : [filters.camera_id],
      categories: (category_api == null || category_api == 'All') ? [] : [category_api],
      tags: filters.tag == 'All' ? [] : [filters.tag],
      fromDate: filters.fromDate == null ? '' : filters.fromDate,
      toDate: filters.toDate == null ? '' : filters.toDate,
      order_by: filters.order_by,
      last_id: lastId,
      last_time: lastTime,
      sort: api_sort,
      length: filters.length,
      video_only: (filters.video_only == null || !filters.video_only) ? 0 : 1,
      favorite_only: (filters.favorite_only == null || !filters.favorite_only) ? 0 : 1,
      xl_only: (filters.xl_only == null || !filters.xl_only) ? 0 : 1,
      is_trash: 1,
    };

    Api.IMAGE_LOAD_ALL(params).then(((res: any) => {
      if (more) {
        setImages(images.concat(JSON.parse(res.text).data));
      } else {
        setImages(JSON.parse(res.text).data);
      }
      setLoading(false);
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  const onScroll = () => {
    httpLoadData(true);
  };

  const selectCard = (index, value) => {
    const newArr = [...images];
    newArr[index].selected = value;
    setImages(newArr);
  };

  const hideModal = () => {
    setActiveCardIndex(-1);
  };

  const reloadPage = () => {
    setImages([]);
    httpLoadData(false);
  };
  // Event
  const onRestore = () => {
    const ids: any = [];

    images.map((e) => {
      if (e.selected) {
        ids.push(e.id);
      }
      return null;
    });

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
    } else {
      const params = {
        ids,
      };

      Api.IMAGE_RESTORE(params).then((() => {
        reloadPage();
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  };

  const onClear = () => {
    const ids: any = [];

    images.map((e) => {
      if (e.selected) {
        ids.push(e.id);
      }
      return null;
    });

    if (ids.length == 0) {
      notification.open({
        message: 'Please select images firstly',
      });
    } else {
      const params = {
        ids,
      };

      Api.IMAGE_DELETE(params).then((() => {
        reloadPage();
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  };

  const onSelectAll = (isSelectAll) => {
    const newArr = [...images];
    newArr.map((image) => {
      image.selected = isSelectAll;
      return null;
    });
    setImages(newArr);
  };

  const handleRightFilter = (willShow) => {
    setShowLeftFilter(willShow);
  };

  const changecategory = (e) => {
    const val = e.target.value;
    setCategory(val);
  };

  return (
    <>
      <Row>
        {md && (
          <Col md={6} xxl={4}>
            <div className={classes.imageFilter}>
              <FilterTrash
                category={category}
                reloadPage={() => reloadPage()}
                handleFilters={(arg) => setFilters(arg)}
                onRestore={() => onRestore()}
                onClear={() => onClear()}
                onSelectAll={(e) => onSelectAll(e)}
              />
            </div>
          </Col>
        )}
        {md == false && (
          <Drawer
            title="Filter & Edit"
            width={300}
            onClose={() => handleRightFilter(false)}
            visible={showLeftFilter}
            zIndex={900}
            handler={
              (
                <div className={classes.drawerHandle}>
                  {
                    showLeftFilter ? <CloseOutlined onClick={() => handleRightFilter(false)} /> : <FilterOutlined onClick={() => handleRightFilter(true)} />
                  }
                </div>
              )
            }
          >
            <FilterTrash
              category={category}
              reloadPage={() => reloadPage()}
              handleFilters={(arg) => setFilters(arg)}
              onRestore={() => onRestore()}
              onClear={() => onClear()}
              onSelectAll={(e) => onSelectAll(e)}
            />
          </Drawer>
        )}
        <Col md={18} xs={24} xxl={20}>
          <Radio.Group value={category} buttonStyle="solid" className={classes.categoryGroup} onChange={changecategory}>
            <Row>
              <Col span={md ? 3 : 6} key={1}><Radio.Button className={classes.categoryBtn} value="All">All</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={2}><Radio.Button className={classes.categoryBtn} value="Deer">Deer</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={3}><Radio.Button className={classes.categoryBtn} value="Hog">Hog</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={4}><Radio.Button className={classes.categoryBtn} value="Turkey">Turkey</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={5}><Radio.Button className={classes.categoryBtn} value="People">People</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={6}><Radio.Button className={classes.categoryBtn} value="Vehicle">Vehicle</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={7}><Radio.Button className={classes.categoryBtn} value="Predator">Predator</Radio.Button></Col>
              <Col span={md ? 3 : 6} key={8}><Radio.Button className={classes.categoryBtn} value="Other">Other</Radio.Button></Col>
            </Row>
          </Radio.Group>
          <Spin spinning={loading} size="large" wrapperClassName={classes.content}>
            <InfiniteScroll
              dataLength={images.length}
              next={onScroll}
              hasMore
              loader=""
              endMessage={
                (
                  <p style={{ textAlign: 'center' }}>
                    <b>No more images</b>
                  </p>
                )
              }
            >
              <Row className={classes.content}>
                {
                  images.map((e, i) => (
                    <Col xs={12} md={6} lg={6} xl={4} key={i}>
                      <ImageCard key={i} index={i} detail={e} onClick={() => { setActiveCardIndex(i); }} onSelect={(arg) => { selectCard(i, arg); }} onFavorite={(index, isFavorite) => { }} />
                    </Col>
                  ))
                }
              </Row>
            </InfiniteScroll>
          </Spin>
        </Col>
      </Row>
      <Modal
        visible={activeCardIndex > -1}
        onOk={hideModal}
        onCancel={hideModal}
        width={1000}
        className={`${classes.mediaModal} image-gallery-modal`}
        bodyStyle={{ marginTop: 32, backgroundColor: 'transparent', padding: 0 }}
        footer={null}
      >
        {activeCardIndex > -1 && (
          <MediaBox
            images={images}
            activeIndex={activeCardIndex}
            onIndexChange={(index) => setActiveCardIndex(index)}
          />
        )}
      </Modal>
    </>
  );
};

export default TrashPage;
