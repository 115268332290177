/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker, MarkerClusterer, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  height: '100%',
};

const LIBRARIES: Array<'places' | 'drawing' | 'geometry' | 'visualization'> = ['places'];

const CameraAddMap = ({ camera, onDragEnd, zoom, cameras }) => {
  const [map, setMap] = useState<any>();
  const [searchBox, setSearchBox] = useState<any>();
  const center = {
    lat: Number(camera.latitude),
    lng: Number(camera.longitude),
  };

  const onLoad = (ref) => setSearchBox(ref);

  const onPlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (Array.isArray(places)) {
        const place = places[0];
        const latLng = place.geometry.location;
        onDragEnd({ latLng });
      }
    }
  };

  const loadHandler = (mapInstance) => {
    setMap(mapInstance);
    // fitBounds(mapInstance);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAnFBws_Bc64W-xl_4HULAoH0oG664xLt4',
    libraries: LIBRARIES,
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom ?? 14}
      onLoad={loadHandler}
      options={{
        mapTypeControl: false,
        mapTypeId: 'hybrid',
      }}
    >
      <StandaloneSearchBox
        onLoad={onLoad}
        onPlacesChanged={onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Search"
          style={{
            boxSizing: 'border-box',
            border: '1px solid transparent',
            width: '240px',
            height: '32px',
            padding: '0 12px',
            borderRadius: '3px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            fontSize: '14px',
            outline: 'none',
            textOverflow: 'ellipses',
            position: 'absolute',
            left: '50%',
            marginLeft: '-120px',
          }}
        />
      </StandaloneSearchBox>
      <MarkerClusterer>
        {(clusterer) =>
          cameras.map((e, i) => (
            e.id != camera.id && (
              <Marker
                key={i}
                title={e.name}
                clusterer={clusterer}
                icon={{ url: `/map_pins/${e.type ? e.type : 'Camera'}-gray.png` }}
                position={{ lat: Number(e.latitude), lng: Number(e.longitude) }}
              />
            )
          ))}
      </MarkerClusterer>
      <Marker
        title={camera.name}
        position={{ lat: Number(camera.latitude), lng: Number(camera.longitude) }}
        draggable
        zIndex={100}
        icon={{ url: `/map_pins/${camera.type ? camera.type : 'Camera'}.png` }}
        onDragEnd={onDragEnd}
      />
    </GoogleMap>
  ) : <></>;
};

export default CameraAddMap;
