import { AreaChartOutlined, CameraOutlined, CloudOutlined, DashboardOutlined, DeleteOutlined, FieldTimeOutlined, FileImageOutlined, GroupOutlined, HomeOutlined, NodeIndexOutlined, SmallDashOutlined, TagsOutlined, HeartOutlined, UserOutlined, SlidersOutlined } from '@ant-design/icons';
import React from 'react';
import { PATHS } from 'res/routes';

export default {
  route: {
    path: '/',
    routes: [
      {
        path: PATHS.HOME,
        name: 'Dashboard',
        icon: <HomeOutlined />,
      },
      {
        path: PATHS.DATA_USAGE,
        name: 'Data Usage',
        icon: <DashboardOutlined />,
      },
      {
        name: 'Images/Videos',
        icon: <FileImageOutlined />,
        routes: [
          {
            path: '/image/All',
            name: 'My Images',
            icon: <FileImageOutlined />,
          },
          {
            path: '/trash/All',
            name: 'Trash',
            icon: <DeleteOutlined />,
          },
          {
            path: PATHS.IMAGE_TRASH_MORE,
            name: 'Trash Manager',
            icon: <DeleteOutlined />,
          },
          {
            path: PATHS.IMAGE_TAG,
            name: 'Tag Manager',
            icon: <TagsOutlined />,
          },
        ],
      },
      {
        name: 'Camera',
        icon: <CameraOutlined />,
        routes: [
          {
            path: PATHS.CAMERA,
            name: 'Cameras/Map',
            icon: <CameraOutlined />,
          },
          {
            path: PATHS.CAMERA_REMOTE,
            name: 'Remote Control',
            icon: <NodeIndexOutlined />,
          },
          {
            path: PATHS.CAMERA_GROUP,
            name: 'Camera Group',
            icon: <GroupOutlined />,
          },
        ],
      },
      {
        path: PATHS.ACTIVITY,
        name: 'Activity',
        icon: <AreaChartOutlined />,
      },
      {
        path: PATHS.BESTTIME,
        name: 'Best Time',
        icon: <FieldTimeOutlined />,
      },
      {
        name: 'Forecast',
        icon: <CloudOutlined />,
        routes: [
          {
            path: PATHS.WEATHER,
            name: 'Weather',
            icon: <CloudOutlined />,
          },
          {
            path: PATHS.PREDICTION,
            name: 'Prediction',
            icon: <CloudOutlined />,
          },
        ],
      },
      {
        path: PATHS.PROFILE,
        name: 'Profile',
        icon: <UserOutlined />,
      },
      {
        path: PATHS.DETECTION_THRESHOLD,
        name: 'Detection Threshold',
        icon: <SlidersOutlined />,
      },
    ],
  },
  location: {
    pathname: '/',
  },
};
