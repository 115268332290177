import { Button, Col, Collapse, Form, Input, Row, Select, Spin, Switch, TimePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { optionsBig } from '../options';
import classes from './style.module.scss';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const format = 'HH:mm';

const CameraRemoteBigAdvanced = ({ setting, onChange, onSend }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [switchTimeLapse, setSwitchTimeLapse] = useState(true);
  const [workTimer1, setWorkTimer1] = useState(true);
  const [workTimer2, setWorkTimer2] = useState(true);

  useEffect(() => {
    if (setting) {
      console.log('setting', setting);

      let lapse = setting.time_lapse;
      if (lapse == 'OFF') {
        setSwitchTimeLapse(false);
        lapse = '00:00:00';
      } else {
        setSwitchTimeLapse(true);
      }

      let work_timer1 = [moment('00:00', format), moment('00:00', format)];
      if (setting.work_timer1 == 'OFF') {
        setWorkTimer1(false);
      } else {
        setWorkTimer1(true);
        const work_time = setting.work_timer1.split('-');
        work_timer1 = [moment(work_time[0], format), moment(work_time[1], format)];
      }

      let work_timer2 = [moment('00:00', format), moment('00:00', format)];
      if (setting.work_timer2 == 'OFF') {
        setWorkTimer2(false);
      } else {
        setWorkTimer2(true);
        const work_time = setting.work_timer2.split('-');
        work_timer2 = [moment(work_time[0], format), moment(work_time[1], format)];
      }
      form.setFieldsValue({ ...setting, timeLapseMoment: moment(lapse, 'HH:mm:ss'), workTimerMoment1: work_timer1, workTimerMoment2: work_timer2 });
    }
  }, [setting]);

  const onFinish = () => {
    onSend();
  };

  useEffect(() => {
    onChangeForm();
  }, [workTimer1, workTimer2, switchTimeLapse]);

  const onChangeForm = () => {
    const values = form.getFieldsValue();
    if (Object.keys(values).length > 0) {
      const updatedSetting = {};
      for (let [key, value] of Object.entries(values)) {
        if (key == 'timeLapseMoment') {
          key = 'time_lapse';
          if (switchTimeLapse) {
            value = values.timeLapseMoment.format('HH:mm:ss');
          } else {
            value = 'OFF';
          }
        } else if (key == 'workTimerMoment1') {
          key = 'work_timer1';
          if (workTimer1) {
            const time1 = values.workTimerMoment1[0].format(format);
            const time2 = values.workTimerMoment1[1].format(format);
            value = `${time1}-${time2}`;
          } else {
            value = 'OFF';
          }
        } else if (key == 'workTimerMoment2') {
          key = 'work_timer2';
          if (workTimer2) {
            const time1 = values.workTimerMoment2[0].format(format);
            const time2 = values.workTimerMoment2[1].format(format);
            value = `${time1}-${time2}`;
          } else {
            value = 'OFF';
          }
        }
        updatedSetting[key] = value;
        key = '';
      }
      onChange(updatedSetting);
    }
  };

  return (
    <Spin spinning={loading} size="large">
      <Collapse defaultActiveKey={[]}>
        <Collapse.Panel header="Advanced Settings" key="1">
          <Form
            {...layout}
            form={form}
            name="basic"
            onValuesChange={onChangeForm}
            onFinish={onFinish}
          >
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Night Mode"
                  name="night_mode"
                  tooltip={{ title: 'This controls the shutter speed at night. Max Range will illuminate things further away, but it will tend to create more blur. Minimum Blur will reduce the amount of blurriness in the photo, but it will reduce the range of the flash at night. Balanced is good for most situations.', placement: 'bottom' }}
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsBig.NightMode}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Time Lapse:"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'Allows you to take pictures at an interval, regardless of the motion sensor. You can set the interval as low as 5 seconds or as high as 23 hrs and 59 minutes. Turning on Time Lapse will disable your motion sensor. If you want the camera to be triggered by motion, make sure the Time Lapse is not enabled.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="timeLapseMoment"
                    >
                      <TimePicker
                        className={classes.control}
                        size="large"
                        disabled={!switchTimeLapse}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={switchTimeLapse}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setSwitchTimeLapse(checked);
                          if (checked) {
                            form.setFieldsValue({ timeLapseMoment: moment('00:00:03', 'HH:mm:ss') });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Work Timer1"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="workTimerMoment1"
                    >
                      <TimePicker.RangePicker
                        className={classes.control}
                        size="large"
                        disabled={!workTimer1}
                        format={format}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={workTimer1}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setWorkTimer1(checked);
                          if (checked) {
                            form.setFieldsValue({ workTimerMoment1: [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')] });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Work Timer2"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="workTimerMoment2"
                    >
                      <TimePicker.RangePicker
                        className={classes.control}
                        size="large"
                        disabled={!workTimer2}
                        format={format}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={workTimer2}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setWorkTimer2(checked);
                          if (checked) {
                            form.setFieldsValue({ workTimer2Moment: [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')] });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className={classes.divFooter}>
              <Button type="primary" htmlType="submit">
                Send
              </Button>
            </div>
          </Form>
        </Collapse.Panel>
      </Collapse>
    </Spin>
  );
};

export default CameraRemoteBigAdvanced;
