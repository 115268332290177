import { AutoComplete, Divider, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import useApi from 'redux/api/api.hook';
import Icon from '@ant-design/icons';
import classes from './style.module.scss';

const { Option } = Select;

const categoryies = [
  'Deer (Antlered)',
  'Deer (Non Antlered)',
  'Hog',
  'Turkey',
  'People',
  'Vehicle',
  'Predator',
  'Other',
];

const ImageEditTag = (props) => {
  const [filteredTags, setFilteredTags] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');
  const { tags, tag_assigns } = useApi();
  const [form] = Form.useForm();

  const handleOk = (values) => {
    setIsModalVisible(false);
    props.onChangeTag(values.tag, values.category, 2);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    updateFilteredTags('');
  }, [tags, tag_assigns]);

  useEffect(() => {
    if (props.selectedTag) {
      setSelectedTag(props.selectedTag.split('$', 2).length > 1 ? props.selectedTag.split('$', 2)[1] : props.selectedTag);
    } else {
      setSelectedTag('');
    }
    updateFilteredTags('');
    console.log('selectedTag changed', props.selectedTag);
  }, [props.selectedImage, props.selectedTag]);

  const updateFilteredTags = (value) => {
    const resAssigned: any = [[]];
    const res: any = [];
    const resNone: any = [];
    if (!value || value == '') {
      tags.forEach((element) => {
        if (element.tag !== '') {
          let flag_assigned = false;
          for (let i = 0; i < tag_assigns.length; i++) {
            const assign = tag_assigns[i];
            for (let j = 0; j < assign.value.length; j++) {
              if (assign.value[j] == element.tag) {
                if (resAssigned[assign.key] == undefined) {
                  resAssigned[assign.key] = [];
                }
                resAssigned[assign.key].push({ label: element.tag, className: assign.key, value: element.tag });
                flag_assigned = true;
                break;
              }
            }
            if (flag_assigned) { break; }
          }
          if (!flag_assigned) {
            resNone.push({ value: element.tag, className: 'None', key: element.tag });
          }
        }
      });
    } else {
      tags.forEach((element) => {
        if (element.tag.toUpperCase().indexOf(value.toUpperCase()) === -1) { return; }

        let flag_assigned = false;
        for (let i = 0; i < tag_assigns.length; i++) {
          const assign = tag_assigns[i];
          for (let j = 0; j < assign.value.length; j++) {
            if (assign.value[j] == element.tag) {
              if (resAssigned[assign.key] == undefined) {
                resAssigned[assign.key] = [];
              }
              resAssigned[assign.key].push({ label: element.tag, className: assign.key, value: element.tag });
              flag_assigned = true;
              break;
            }
          }
          if (flag_assigned) { break; }
        }
        if (!flag_assigned) {
          resNone.push({ value: element.tag, className: 'None', key: element.tag });
        }
      });
    }

    categoryies.forEach((category) => {
      if (resAssigned[category] && resAssigned[category].length > 0) {
        res.push({ label: category, options: resAssigned[category] });
      }
    });

    if (resNone.length > 0) {
      res.push({ label: 'None', options: resNone });
    }

    if (res.length == 0) {
      res.push({ label: 'None', options: [] });
    }

    setFilteredTags(res);
  };

  const tagSearch = (value) => {
    console.log('tagSearch');
    setSelectedTag(value);
    updateFilteredTags(value);
  };

  const tagChange = (value, option) => {
    console.log('tagChange');
    props.onChangeTag(value, option.className, 0);
  };

  const addItem = () => {
    console.log('addItem');
    setIsModalVisible(true);
  };

  const clearTag = () => {
    console.log('clear tag');
    props.onChangeTag('', 'None', 1);
  };

  return (
    <>
      <AutoComplete
        value={selectedTag}
        onSelect={tagChange}
        onSearch={tagSearch}
        placeholder="Change Tag To:"
        dropdownStyle={{ position: 'fixed' }}
        options={filteredTags}
        dropdownRender={(menu) => (
          <div>
            <div
              style={{ padding: '4px 8px', cursor: 'pointer' }}
              onClick={addItem}
            >
              <Icon type="plus" /> Add Tag
            </div>
            <div
              style={{ padding: '4px 8px', cursor: 'pointer' }}
              onClick={clearTag}
            >
              <Icon type="clear" /> Clear
            </div>
            <Divider style={{ margin: '4px 0' }} />
            {menu}
          </div>
        )}
      />
      <Modal
        title="Create New Tag"
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="control-hooks"
          onFinish={handleOk}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            name="tag"
            className={classes.formInputWrapper}
            rules={[{ required: true, message: 'Please input tag!' }]}
          >
            <Input size="large" placeholder="Input new tag" />
          </Form.Item>
          <Form.Item name="category" className={classes.formInputWrapper}>
            <Select
              size="large"
              placeholder="Assign to Category (Optional)"
              dropdownStyle={{ position: 'fixed' }}
            >
              {categoryies.map((val, i) => (
                <Option key={i} value={val}>{val}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ImageEditTag;
