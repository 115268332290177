import { Button, Col, Form, Grid, Input, InputNumber, notification, Row, Select } from 'antd';
import * as Api from 'api';
import CameraAddMap from 'components/camera/cameraAddMap';
import { CameraPinType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const pinTypes = [
  { value: 'Stand', name: 'Stand' },
  { value: 'Feeder', name: 'Feeder' },
  { value: 'Camp', name: 'Camp' },
  { value: 'Trail', name: 'Trail' }];

const CameraAddPin = () => {
  // const { cameras, filterOptions, setCameras } = useApi();
  const [form] = Form.useForm();
  const { md } = useBreakpoint();
  const history = useHistory();

  const { auth: { profile } } = useAuth();
  const { cameraMapBound } = useApi();
  console.log('Camera Map Bound==> ', cameraMapBound);
  const [cameras, setCameras] = useState<CameraPinType[]>([]);
  const [loading, setLoading] = useState(false);
  const [camera, setCamera] = useState<CameraPinType>({
    id: 0,
    name: '',
    IMEI: '',
    latitude: cameraMapBound.center.lat,
    longitude: cameraMapBound.center.lng,
    type: 'Stand',
    fix_location: 1,
  });

  useEffect(() => {
    httpGetCameras();
  }, []);

  const onValuesChange = (values, allvalues) => {
    console.log(allvalues);
    const newCamera = { ...camera, ...allvalues };
    setCamera(newCamera);
  };

  const onFinish = (values) => {
    console.log('cameraADD - Success2:', values);
    const params = {
      user_id: profile.user_id,
      name: camera.name,
      id: 0,
      latitude: camera.latitude,
      longitude: camera.longitude,
      type: camera.type,
    };
    Api.MAP_CREATE_PIN(params).then(((res: any) => {
      const data = JSON.parse(res.text);
      if (data.result == 'ok') {
        const path = '/camera';
        history.push(path);
      } else {
        notification.open({
          message: data.msg,
        });
      }
      // setForecastdata(data.data);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onDragEnd = (evt) => {
    const { latLng } = evt;
    // updateMarkerPositionTxt(latLng);

    const newCamera = {
      ...camera,
      ...{
        latitude: Number(latLng.lat().toFixed(6)),
        longitude: Number(latLng.lng().toFixed(6)),
      },
    };
    setCamera(newCamera);
    form.setFieldsValue(newCamera);
  };

  const httpGetCameras = () => {
    setLoading(true);
    Api.CAMERA_GET_LIVE_ALL().then(((res: any) => {
      setLoading(false);
      const result = JSON.parse(res.text);
      setCameras(result.data);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <>
      <Row>
        {md && (
          <Col md={12} className={classes.leftMap} style={{ height: '80vh' }}>
            <CameraAddMap camera={camera} onDragEnd={onDragEnd} zoom={cameraMapBound.zoom} cameras={cameras} />
          </Col>
        )}
        <Col md={12} xs={24}>
          <div className={classes.content} style={{ padding: md ? 24 : 16 }}>
            <Form
              {...layout}
              form={form}
              name="basic"
              initialValues={camera}
              onValuesChange={onValuesChange}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input pin name',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Latitude"
                name="latitude"
                rules={[
                  {
                    required: true,
                    message: 'Latitude',
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                label="Longitude"
                name="longitude"
                rules={[
                  {
                    required: true,
                    message: 'Longitude',
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Type',
                  },
                ]}
              >
                <Select size="large">
                  {pinTypes.map((val: any) => (
                    <Option key={val.value} value={val.value}>{val.name}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
            {md == false && (
              <Col xs={24} md={24} style={{ height: '400px' }}>
                <CameraAddMap camera={camera} onDragEnd={onDragEnd} zoom={cameraMapBound.zoom} cameras={cameras} />
              </Col>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CameraAddPin;
